<template>
  <div class="meetItem_container">
    <div class="meetItem_title_date">
      <div style="display: flex; justify-content: space-between">
        会议时间：{{ item.meet_start_time.slice(0, -3) }}
        <CustomButton class="CustomButton copyButton" @click="copyMeet(item)"
          >复制观众链接</CustomButton
        >
      </div>
      <div>会议讲题：{{ item.meet_topics }}</div>
    </div>
    <div></div>
    <div
      class="reason"
      v-if="item.audit_status == 30 || item.audit_status == 50"
    >
      <div>反馈日期：{{ item.audit_time.substring(0, 11) }}</div>
      <div>失败理由：{{ item.audit_reason }}</div>
    </div>
    <div class="reason" v-if="item.audit_status == 25">
      <div>
        反馈日期：{{ item.audit_time.substring(0, 11) }}
        &nbsp;&nbsp; 基金会审核中
      </div>
    </div>
    <div class="reason" v-if="item.pay_status == 2">
      <div>付款日期：{{ item.pay_date.substring(0, 11) }}</div>
      <div>失败理由：{{ item.pay_reason }}</div>
    </div>
    <div class="reason" v-if="item.pay_status == 1">
      <div>付款日期：{{ item.pay_date.substring(0, 11) }}</div>
      <div>付款单位：{{ data.agreement_company_name }}</div>
    </div>
    <div class="meetItem_group_btn">
      <div style="display: flex">
        <CustomButton
          style="margin-right: 0.5rem"
          class="CustomButton"
          :class="{
            statusButton: item.status == 0,
            statusEndButton: item.status > 1,
          }"
          >{{ getStatusText(item) }}
        </CustomButton>
      </div>
      <div style="display: flex">
        <CustomButton class="CustomButton" @click="goToMeetInfo"
          >详细信息</CustomButton
        >
        <!-- 管理员 观看会议 医生 去开会 -->
        <CustomButton
          v-if="item.status <= 1"
          style="margin-left: 0.5rem"
          class="CustomButton endButton"
          @click="goToMeet(item, type)"
          >{{ type == 1 ? "观看会议" : "去开会" }}</CustomButton
        >
        <CustomButton
          v-if="item.audit_status == 25"
          style="margin-left: 0.5rem"
          class="CustomButton endButton"
          >基金会审核中</CustomButton
        >
        <!-- 已召开 或者会议超超时并人工审核通过 基金会审核中前
         -->
        <CustomButton
          style="margin-left: 0.5rem"
          @click="goToAgreement(item)"
          class="CustomButton endButton"
          v-if="
            (!(item.audit_status == 25 || item.audit_status > 30) &&
              item.status == 2) ||
            (item.status == 3 && item.audit_status == 20)
          "
        >
          {{ getAgreementText(item) }}</CustomButton
        >
        <!-- 基金会审核成功 显示支付状态  我的支付状态-->
        <CustomButton
          v-if="item.audit_status == 40 && item.pay_status > 0"
          style="margin-left: 0.5rem"
          class="CustomButton endButton"
        >
          <!-- @click="payStatus(item)" -->
          {{ this.$config.types.pay[item.pay_status] }}
        </CustomButton>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/unit/CustomButton";
import PopopInstance from "@/components/unit/PopupInstance";
export default {
  name: "meetItem",
  components: { CustomButton },
  props: {
    item: {
      type: Object,
      default: {},
    },
    type: {
      type: Number,
      default: 0,
    },
    user_id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return { userLogin: {} };
  },
  created() {
    this.userLogin =
      this.$tools.getStorage("userLogin", localStorage) ||
      this.$tools.getStorage("saleLogin", localStorage);
  },
  mounted() {},
  methods: {
    getStatusText(item) {
      if (item.is_test) {
        return "测试中";
      } else {
        return this.$config.types.status[item.status];
      }
    },
    getAgreementText(item) {
      if (this.type == 1) {
        if (!item.sign_name) {
          return "劳务签署提醒";
        } else {
          return "协议已签署";
        }
      } else {
        if (!item.sign_name) {
          return "签署协议";
        } else {
          return "协议已签署";
        }
      }
    },
    copyMeet(item) {
      var input = document.createElement("input");
      let content = `${this.$config.host}/#/live?id=${item.id}`;
      input.value = content;
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      document.body.removeChild(input);
      this.$message.success("复制成功！");
    },
    goToMeetInfo() {
      this.$tools.setStorage("coursewareData", {});
      this.$tools.setStorage("meetData", {});
      this.$router.push({
        path: "/meetInfo",
        query: {
          user_id: this.$route.query.id || this.user_id,
          meet_id: this.item.id,
          form: this.$route.path,
        },
      });
    },
    goToMeet(item) {
      this.$emit("goToMeet", item);
    },
    goToAgreement(item) {
      if (this.type == 1) {
        this.$tips.success({ text: "劳务签署提醒" });
      } else {
        this.$router.push({
          path: "/agreement",
          query: {
            meet_id: item.id,
            from: this.$route.path,
            user_id: this.$route.query.id || this.user_id,
            ...this.$route.query,
          },
        });
      }
    },
    //我的支付状态
    payStatus(data) {
      console.log("object :>> ", data.status); //0 待支付 1 已支付 2 失败
      let msg = {
        title: "我的支付状态",
        html: `<div class="popup-content-main">已提交协议，基金会正在审核支付中。提交后预估14天到账，请留意短信及本平台微信推送。</div>`,
        btngroup: { sure: "确认" },
        align: "center",
      };
      if (data.status == 1) {
        msg.html = `<div class="popup-content-main">已支付\n支付时间：${data.doctor_pay_time}\n支付金额：${data.doctor_pay_money}\n支付方：${data.doctor_pay_name}</div>`;
      } else if (data.status == 2) {
        msg.html = `<div class="popup-content-main">支付失败\n反馈日期：${data.feed_back_time}\n失败原因：${data.feed_message_info}</div>`;
      }
      PopopInstance(msg).then(() => {});
    },
  },
};
</script>

<style>
.meetItem_container {
  border-bottom: 1px solid #ccc;
}
.meetItem_title_date {
  border-left: 4px solid #1aa6ec;
  font-weight: 400;
  font-size: 14px;
  color: #606060;
  line-height: 1.5;
  margin: 8px 0;
  padding: 4px;
  padding-right: 0;
}
.meetItem_title_date div {
  white-space: nowrap; /* 确保文本在一行内显示 */
  overflow: hidden; /* 隐藏溢出的内容 */
  text-overflow: ellipsis; /* 使用省略号表示文本被截断 */
}
.meetItem_group_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.meetItem_container .CustomButton {
  width: auto !important;
  padding: 2px 18px;
  /* padding: 2px 6px; */
  height: 20px !important;
}
.meetItem_container .statusButton {
  background: #fcd6cc !important;
  color: #1aa6ec !important;
}
.meetItem_container .statusEndButton {
  background: #dee0e9 !important;
  color: #132460 !important;
}
.meetItem_container .endButton {
  background: #132460 !important;
}
.meetItem_container .copyButton {
  background: #fcd6cc !important;
  color: #1aa6ec !important;
  width: 100px !important;
}
.meetItem_container .reason {
  color: red;
  font-size: 14px;
  margin-bottom: 8px;
}
</style>
