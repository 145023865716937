<template>
  <div
    class="customButton_container global-center customButton_background"
    :class="getClass()"
  >
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "customButton",
  props: {
    size: {
      // size用于区分 长 中 短
      type: String,
      default: "middle",
    },
    type: {
      // size用于区分 info primary
      type: String,
      default: "primary",
    },
    round: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      config: {
        height: {
          large: 16,
          middle: 14,
          small: 12,
        },
      },
    };
  },
  methods: {
    getClass() {
      return `customButton_${this.size} customButton_${this.type}`;
    },
  },
};
</script>
<style >
.customButton_container {
  font-size: 14px;
  line-height: 18px;
  height: 18px;
  /* color:#fff; */
  padding: 0 12px;
  border-radius: 20px;
  width: auto;
  cursor: pointer;
}

.customButton_primary {
  background: #1aa6ec;
  color: #fff;
}
.customButton_success {
  background: #22b783;
  color: #fff;
}

.customButton_info {
  background: #efefef;
  color: #000;
}
.customButton_custom {
  background: rgb(254, 232, 241);
  color: #1aa6ec;
}
.customButton_large {
  width: 100%;
}
.customButton_middle {
  height: 29px;
  /* line-height: 29px; */
  width: 200px;
}
.customButton_small {
  width: 200px;
}
.customButton_mini {
  width: auto;
}
.customButton_background {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
